type scrollBehavior = 'smooth' | 'auto';

export const scrollTo = (selector: string, behavior?: scrollBehavior) => {
  const element = document.querySelector(selector);
  if (element !== null) {
    element.scrollIntoView({behavior: behavior || 'auto'});
  }
};

export const scrollToError = (refs, field) => {
  if (!(field in refs)) { return; }
  return refs[field].$el.scrollIntoView({behavior: 'smooth'});
};
