





























































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import {IAddress} from '@/services/api/user.service';
import ServerValidation from '@/mixins/serverValidation.mixin';
import {mixins} from 'vue-class-component';
import '@/validators/vee-validate';
import {
  defaultAddressManual,
  defaultPropertyAddressManual
} from '@/store/modules/user.store';
import FormObserverMixin from '@/mixins/formObserver.mixin';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider
  },
})
export default class AddressManual extends mixins(ServerValidation, FormObserverMixin) {
  public $refs!: {
    searchStreetNumber: Vue
  };

  @Prop({required: true})
  private address!: IAddress;

  @Prop({type: Boolean, default: false})
  private isShowCountry: boolean | undefined;

  @Prop({type: Boolean})
  private isShowBackToSearch: boolean | undefined;

  @Prop({type: Boolean, default: false})
  private isDisabled: boolean | undefined;

  @Watch('isManualAddressValid')
  private watchManualAddressValid(value) {
    this.$emit('setManualAddressValid', value);
  }

  private mounted() {
    this.$emit('setManualAddressValid', this.isManualAddressValid);
  }

  private get isManualAddressValid() {
    return !!(this.address.address?.streetNumber
        && this.address.address?.street
        && this.address.address?.zip
        && this.address.address?.city)
      && (this.isShowCountry ? this.address.address?.country : true);
  }

  private backToSearch() {
    this.address.address = this.isShowCountry ? defaultAddressManual() : defaultPropertyAddressManual();
    this.observer.reset();
    this.$emit('setManualAddressValid', false);
    this.$emit('setManualAddress', false);
  }

  private get getCountries() {
    return this.$store.getters.getDictionaryArray('countries');
  }

}
