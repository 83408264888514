









import {Prop, Component} from 'vue-property-decorator';
import {Language} from '@/interfaces';
import {EnvProvider} from '@/utilities';
import store from '@/store';
import UrlWithParams from '@/mixins/urlWithParams.mixin';

@Component
export default class DocumentLink extends UrlWithParams {

  @Prop({type: String, required: true})
  private documentName!: string;

  private get getDocumentUrl() {
    const language = store.getters.language as Language;
    const url = this.replaceDomain(EnvProvider('URL_WEBSITE'));
    return `${url}/documents/${language}/${this.documentName}`;
  }
}
